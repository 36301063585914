import React from 'react';
import { Helmet } from 'react-helmet'
import { Typography, Box, CardMedia } from '@mui/material'
import { makeStyles } from '@mui/styles'
import audioGalleryShot from "../images/audioGalleryShotCopy.jpg"
import audioResearch from "../images/audioResearch.png"
import jbl from "../images/jbl.png"
import mcIntosh from "../images/mcIntosh.png"
import amplifier from "../images/amplifier.png"
import cdPlayer from "../images/cd-player.png"
import speaker from "../images/speaker.png"
import vinyl from "../images/vinyl.png"
import { PriorityHigh, Search } from '@mui/icons-material';
import SiteHeader from '../components/header.js'
import theme from '../components/theme.js'
import { ThemeProvider } from '@mui/material/styles';
import getFirebase from '../components/firebaseConfig'
import Closed from '../components/closed.js';
getFirebase()

const frontPageStyle = makeStyles({
	banner: {
		position: "relative",
		backgroundColor: "black",
		display: "flex",
		justifyContent: "center",
	}
})

const HomePage = () => {
	let classes = frontPageStyle()
	theme.typography.body1.fontSize = 21
	return (
		<ThemeProvider theme={theme}>
			<Box style={{ position: "relative" }}>
				<Helmet defer={false}>
					<title>Sunny Audio</title>
					<meta name="robots" content="index, follow" />
					<meta name="description"
						content="Here at Sunny Audio, 
                we've been buying and selling high-end vintage audio equipment 
                for over 20 years."></meta>
				</Helmet>
				<SiteHeader currentPage="Home" />
				<Box sx={{
					alignItems: "center",
					display: "flex",
					flexDirection: "column",
					marginBottom: "120px",
					width: "100vw"
				}}>
					<Box className={classes.banner}>
						<CardMedia src={audioGalleryShot} component="img" alt="Picture of Sunny Audio's storefront" sx={{
							width: "100vw",
							opacity: 0.4,
							'@media screen and (max-width: 800px)': {
								width: "100vw"
							}
						}}
						/>
						<Box style={{ position: "absolute", top: "0", left: "0", zIndex: 2, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
							<Box component="a"
								href="/searchAllListings"
								sx={{
									transition: "color 0.25s ease-in-out, backgroundColor 0.25s ease-in-out",
									backgroundColor: "white",
									borderRadius: "15px",
									position: "absolute",
									padding: "15px 50px 15px 50px",
									textDecoration: "none",
									color: "black",
									'&:hover': {
										backgroundColor: "black",
										color: "white",
										cursor: "pointer",
									},
								}}
							>
								<Typography variant="h2" align="center">View our listings</Typography>
							</Box>
						</Box>
					</Box>
					<br />
					<Box
						display="flex"
						alignItems="center"
						flexDirection="column"
						rowGap="45px"
						width="80vw"
						sx={{
							"& > div": {
								width: "80vw",
							},
						}}
					>
						<Typography align="center">Here at Sunny Audio (formerly Sunny Buy and Sell),
							we've been buying and selling high-end vintage audio equipment
							for over 20 years. Down below you can learn more about our business,
							find our location and contact information, and
						<span style={{ fontWeight: "bold" }}> look over our business's policies on
								dealing with buyers and sellers.</span>
						</Typography>
						<Box display="flex" marginTop="40px" columnGap='40px' alignItems="center">
							<Box sx={{
								display: 'flex',
								flexDirection: "column",
								rowGap: "25px",
								flexShrink: 0,
								alignItems: "center",
								width: "240px",
								"& img": {
									maxWidth: "200px",
									maxHeight: "110px"
								},
								[theme.breakpoints.down('sm')]: {
									width: "100px",
									"& img": {
										maxWidth: "100px",
									},
								}
							}}
							>
								<img src={jbl} alt="JBL logo" />
								<img src={audioResearch} alt="Audio Research logo" />
								<img src={mcIntosh} alt="McIntosh logo" />
							</Box>
							<Typography>Our store currently has over 200 listings from a wide variety of brands, such as
								Yamaha, JBL, Audio Research, McIntosh and Pioneer.
							</Typography>
						</Box>
						<Box display="flex" marginTop="40px" columnGap='40px' alignItems="center">
							<Box sx={{
								display: 'flex',
								flexShrink: 0,
								flexDirection: "row",
								justifyContent: "space-between",
								flexWrap: "wrap",
								alignItems: "center",
								rowGap: "25px",
								width: "240px",
								"& img": {
									flexGrow: 0,
									maxWidth: "110px",
									maxHeight: "110px",
								},
								[theme.breakpoints.down('sm')]: {
									width: "100px",
									"& img": {
										maxWidth: "45px",
									},
								}
							}}
							>
								<img src={vinyl} alt="Vinyl icon" />
								<img src={speaker} alt="Speaker icon" />
								<img src={amplifier} alt="Amplifier icon" />
								<img src={cdPlayer} alt="CD Player icon" />
							</Box>
							<Typography > We have all sorts of audio equipment,
								like speakers, amplifiers, receivers, turntables, cassette decks, and more!
							</Typography>
						</Box>
						<Box display="flex" marginTop="40px" columnGap='40px' alignItems="center">
							<Box sx={{
								display: 'flex',
								flexDirection: "column",
								rowGap: "25px",
								flexShrink: 0,
								alignItems: "center",
								width: "240px",
								[theme.breakpoints.down('sm')]: {
									width: "100px",
								}
							}}
							>
								<PriorityHigh sx={{ fontSize: "125px" }} />
							</Box>
							<Typography >Click on ‘Featured Listings’ to see the best of what we have to offer. Come back every few days to see if we have anything new.</Typography>
						</Box>
						<Box display="flex" marginTop="40px" columnGap='40px' alignItems="center">
							<Box sx={{
								display: 'flex',
								flexDirection: "column",
								rowGap: "25px",
								flexShrink: 0,
								alignItems: "center",
								width: "240px",
								[theme.breakpoints.down('sm')]: {
									width: "100px",
								}
							}}
							>
								<Search sx={{ fontSize: "125px" }} />
							</Box>
							<Typography >  Click on 'Search all listings' to go through all our listings by name, and filter the results to your liking.
							</Typography>
						</Box>
						<Box display="flex" flexDirection="column" rowGap="60px" marginTop="25px">
							<Typography align="center" variant="h3" style={{ fontWeight: "bold" }}>
								Contact Info and Address
							</Typography>
							<Typography align="center"  >Give us a call at <span style={{ fontWeight: "bold" }}>416-539-9170</span> for more information or to schedule
								an appointment to buy or sell an item.</Typography>
							<Typography align="center"  >
								Our store is located at <span style={{ fontWeight: "bold" }}>1273 Bloor St. West, Toronto, ON, M6H 1N7</span>.</Typography>
							<Typography align="center" variant="h3" style={{ fontWeight: "bold" }}>
								Business Policies
							</Typography>
							<Typography align="center" >We DO NOT do deliveries. You must come to our premises at the above address to pick up or sell an item.
							</Typography>
							<Typography align="center" >
								The prices listed on this website may or may not be negotiable. You can phone us for more information on a specific item.
							</Typography>
							<Typography align="center" >When you come to our shop,
								you can test out an item as much as you want before buying it to see if you're satisfied.
								However, we DO NOT offer warranty. We also DO NOT offer refunds or item exchanges.
							</Typography>
							<Typography align="center" >For better customer service, we require all customers to schedule an appointment with us before entering our store to buy or sell an item.</Typography>
						</Box>
					</Box>
				</Box>
				<Box display="flex" justifyContent="center" backgroundColor="black">
					<Typography>
						<a href="https://www.flaticon.com/free-icons/speaker" title="speaker icons">Speaker icons created by monkik - Flaticon</a>
						<br />
						<br />
						<a href="https://www.flaticon.com/free-icons/cd-player" title="cd player icons">Cd player icons created by Freepik - Flaticon</a>
						<br />
						<br />
						<a href="https://www.flaticon.com/free-icons/lp" title="Lp icons">Lp icons created by Those Icons - Flaticon</a>
						<br />
						<br />
						<a href="https://www.flaticon.com/free-icons/amplifier" title="amplifier icons">Amplifier icons created by Ehtisham Abid - Flaticon</a>
					</Typography>
				</Box>
			</Box>
		</ThemeProvider>
	)
}

export default HomePage
